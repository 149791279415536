function form(element,requiredfields)
{
	this.element = jQuery(element);
	this.requiredfields = requiredfields;
}

form.prototype.verify = function()
{
	var continueon = 1;
	var i = 0;
	while(this.requiredfields[i])
	{
		if(jQuery("#"+this.requiredfields[i]).val() == "")
		{
			continueon = 0;
			jQuery("#"+this.requiredfields[i]).addClass("error bg-danger");
		}
		else
		{
			jQuery("#"+this.requiredfields[i]).removeClass("error bg-danger");
		}
		i++;
	}

	if(!continueon)
	{
		
		this.error("Please Fill in the Fields Marked in Red");
	}
	else
	{
		this.element.find(".error_message").slideUp('fast');
	}

	return continueon;
};

form.prototype.submit = function(){
	
	this.element.submit();
};

form.prototype.error = function(message,fields){
	var focus_element = this.element;

	var form_offset = this.element.offset();
	//form_offset.top = 0;
	jQuery("body, html").animate({'scrollTop':form_offset.top-150},'fast',function(){
		focus_element.find(".error_message").html(message);
		focus_element.find(".error_message").slideDown('fast');
	});

	if(fields)
	{
		var i = 0;
		while(fields[i])
		{
			$("#"+fields[i]).addClass("error bg-danger");
			//$("#"+fields[i]).addClass("bg-danger");
			i++;
		}
	}
};

function register()
{
	var register_form = new form("#register_form",[
			"register_email",
			"register_password",
			"register_fullname",
			"register_repassword"
		]);

	if(register_form.verify())
	{
		if($("#register_password").val() == $("#register_repassword").val())
		{
			$("#register_password").removeClass("error");
			$("#register_repassword").removeClass("error");
			var formdata = $("#register_form").serialize();

			$.getJSON(AJAX_URL,formdata,function(data){
			
				if(data.error)
				{
					register_form.error(data.message);
				}
				else
				{
					$("#register_form_holder").slideUp('fast',function(){
						$("#register_form_holder_registered").slideDown('fast');
					
					});
					
				}
			});
		}
		else
		{
			register_form.error("Passwords do not match");
			$("#register_password").addClass("error");
			$("#register_repassword").addClass("error");
		}
	}
}

function login()
{
	var login_form = new form("#login_form",[
			"login_email",
			"login_password"
		]);

	if(login_form.verify())
	{

			var formdata = $("#login_form").serialize();

			$.getJSON(AJAX_URL,formdata,function(data){
			
				if(data.error)
				{
					login_form.error(data.message);
				}
				else if(data.message == "valid")
				{
					if($("#login_form_successjs").val())
					{
						eval($("#login_form_successjs").val());
					}
					else if($("#redirect").val() && $("#redirect").val != "")
					{
						parent.location = $("#redirect").val();
					}
					else
					{
						parent.location = PLAYER_URL;	
					}
					
				}
				else
				{
					alert(data.message);
				}
			});

	}
}


function profile_form()
{
	$(".profile_success").slideUp('fast');
	var profile_form = new form("#profile_form",[
			"profile_display_name"
		]);

		if(profile_form.verify())
		{
			var formdata = $("#profile_form").serialize();

			$.getJSON(AJAX_URL,formdata,function(data){
			
				if(data.error)
				{
					profile_form.error(data.message);
				}
				else
				{
					if($("#nextsteps").val() == "1")
					{
						parent.location = "?p=nextsteps";
					}
					else
					{
						$("body, html").animate({'scrollTop':'0'},'fast',function(){
							$(".profile_success").slideDown('fast');
						});
					}
					
				}

			});
		}
}




function forgot()
{
	var forgot_form = new form("#forgot_form",["forgot_email"]);
	if(forgot_form.verify())
	{
		$.getJSON(AJAX_URL,{'c':'users','f':'forgotpassword','email':$("#forgot_email").val()},function(data){
			if(data.error)
			{
				forgot_form.error(data.message);
			}
			else
			{
				$("body, html").animate({'scrollTop':'0'},'fast',function(){
					$(".password_success").slideDown('fast');
					$("#forgot_submit").slideUp('fast');
				});
			}
		});
	}
}


function changepassword()
{
	var forgot_form = new form("#password_form",["login_oldpassword","login_newpassword","login_renewpassword"]);
	if(forgot_form.verify())
	{
		if($("#login_newpassword").val() == $("#login_renewpassword").val())
		{
			$("#login_newpassword").removeClass("error");
			$("#login_renewpassword").removeClass("error");
			$.getJSON(AJAX_URL,{'c':'users','f':'changepassword','newpassword':$("#login_newpassword").val(),"oldpassword":$("#login_oldpassword").val()},function(data){
				if(data.error)
				{
					forgot_form.error(data.message);
				}
				else
				{
					$("body, html").animate({'scrollTop':'0'},'fast',function(){
						$(".password_success").slideDown('fast');
					});
				}
			});
		}
		else
		{
			$("#login_newpassword").addClass("error");
			$("#login_renewpassword").addClass("error");
			forgot_form.error("New Passwords Do Not Match");
		}
	}
}

function homefront_form(edit)
{

	var homefront_form = new form("#homefront_form",[
			"homefront_name","homefront_description"
		]);

		if(homefront_form.verify())
		{
			var formdata = $("#homefront_form").serialize();

			$.getJSON(AJAX_URL,formdata,function(data){
			
				if(data.error)
				{
					homefront_form.error(data.message);
				}
				else
				{
					if(edit)
					{
						alert("Homepage Details Modified");
						location.reload();
					}
					else
					{
						var districtid =$("#form_districtid").val();
						var lotid = $("#form_lotid").val();
						
						window.mapgridc.updatesection($("#form_sectionid").val(),$("#form_lotid").val());
						parent.location = "?p=claim.build&districtid="+districtid+"&lotid="+lotid;
					}

				}

			});
		}
}

var loginpop = {
	show:function(element){
		
		var successjs = $("#login_form_successjs").val();
		
		if(!$("#login_popup").length)
		{
			$("body").append('<div id="login_popup"><table border="0" height="100%" width="100%"><tr><td valign="center"><center><div class="login_popup_content"><img src="'+HOME_URL+'/frontend/images/loader.gif"></div></center></td></tr></table></div>');
		}
		$("#login_popup").slideDown('fast');

		$.get(HOME_URL + "frontend/login.php",{'successjs':successjs,'ajax':'1'},function(data){
			$("#login_popup .login_popup_content").html(data);
		});


	},
	hide:function(){
		$("#login_popup").slideUp('fast');
	}

};

function placecomplete(elementid)
	{

		var pthis = this;
		this.element = $(elementid);
		
		this.element.keyup(function(){
			var options = {
	  
	  				types: ['(cities)']
				};
			autocomplete = new google.maps.places.Autocomplete(document.getElementById(elementid.replace("#","")), options);
		 

		  	

		});
	}

	function forgot()
	{
		var email = $("#forgot_email").val();
		if(email)
		{
			$.getJSON(AJAX_URL,{'c':'users','f':'forgotpassword','email':email},function(data){
				alert(data.message);
				if(!data.error)
				{
					parent.location = "index.php";
				}

			});
		}
	}


var albumhtml = "";
$(document).ready(function(){

	$("#logo").addClass("animate");
	setTimeout(function(){
		$("#logo_overlay").fadeOut(1200);
	},3200);

	$(".album_image").click(function(){
		var image = $(this).attr("src");
		$(this).remove();
		albumhtml += "<img src='"+image+"'>";
	});
	albumscroll.scroll();
});



var albumscroll = {
	scroll:function(){
		$(".albums_holder").animate({'left':'-150px'},2000,'linear',function(){
			var firstimage = $(".albums img").eq(0);
			$(".albums img").eq(0).remove();
			$(".albums_holder").append(firstimage);
			$(".albums_holder").css("left","0");
			albumscroll.scroll();
		});
	}

};	


function account_update()
{
var register_form = new form("#register_form",[
			"register_email",
			
			"register_fullname"
		]);

	if(register_form.verify())
	{
		if($("#register_password").val() == $("#register_repassword").val())
		{
			$("#register_password").removeClass("error");
			$("#register_repassword").removeClass("error");
			var formdata = $("#register_form").serialize();

			$.getJSON(AJAX_URL,formdata,function(data){
			
				if(data.error)
				{
					register_form.error(data.message);
				}
				else
				{
					alert("Account Information Updated");
					location.reload();
					
				}
			});
		}
		else
		{
			register_form.error("Passwords do not match");
			$("#register_password").addClass("error");
			$("#register_repassword").addClass("error");
		}
	}
}